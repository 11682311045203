import React, { useState } from "react";
import { ConfigProvider, Layout, Space, theme } from "antd";

import AttrBar from "../attr-bar";
import ContentContainer from "../ContentContainer";
import ProblemSample from "../sample-problem";

const { Header, Content, Footer } = Layout;

interface OwnProps {
  problemId?: string;
  solutionId?: string;
  expanded?: boolean;
  setExpanded?: any;
}

const Problem: React.FC<OwnProps> = ({
  problemId,
  solutionId,
  // expanded,
  // setExpanded,
}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "#fff",
            headerHeight: "auto",
            headerPadding: "12px 12px",
            footerPadding: "12px 12px",
          },
        },
      }}
    >
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            {/* <TopBar /> */}
            <AttrBar
              expanded={expanded}
              setExpanded={setExpanded}
              problemId={problemId}
              solutionId={solutionId}
              expanded2={expanded2}
              setExpanded2={setExpanded2}
            />

            <ProblemSample
              kind={"problem"}
              expanded={expanded}
              setExpanded={setExpanded}
              problemId={problemId}
              solutionId={solutionId}
            />

            <ProblemSample
              kind={"solution"}
              expanded={expanded2}
              setExpanded={setExpanded2}
              problemId={problemId}
              solutionId={solutionId}
            />
          </Space>
        </Header>

        <Content style={{ padding: "12px", background: "none" }}>
          <ContentContainer
            problemId={problemId}
            solutionId={solutionId}
            colorBgContainer={colorBgContainer}
            borderRadiusLG={borderRadiusLG}
          />
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default Problem;
