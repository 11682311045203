import axios from "axios";
import { jsonApiPath } from "../consts";
import { useQuery } from "@tanstack/react-query";

export async function fetchData({ queryKey }: any) {
  const [path] = queryKey;
  const url = jsonApiPath + path + "/";

  try {
    const result = await axios(url);

    if (result.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return result.data;
  } catch (error) {
    throw new Error("Network error " + path + " " + error);
  }
}

export const useDataQuery = (path: string) => {
  const { data } = useQuery({
    queryKey: [path],
    queryFn: fetchData,

    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    gcTime: 5 * 60 * 1000,
  });

  return data;
};
