import { CollapseProps, Space, Typography } from "antd";
import React, { CSSProperties, useState } from "react";
import { theme } from "antd";
import CardSample from "./sample-card";

import { useDataQuery } from "./hooks/useData";

const { Text, Link, Title, Paragraph } = Typography;

const ContentContainer = ({
  problemId = "",
  solutionId = "",
  kind = "problem",
  colorBgContainer,
  borderRadiusLG,
}: {
  problemId?: string;
  solutionId?: string;
  kind?: "problem" | "solution";
  colorBgContainer: string;
  borderRadiusLG: number;
}) => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const text = "wfewf";

  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
    panelStyle
  ) => [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
      style: panelStyle,
    },
  ];

  // const [expanded, setExpanded] = useState(false);

  const [cardData, setData] = useState<any[] | undefined>();

  const problemAttrs: any[] = useDataQuery("attrs");
  const solutionAttrs: any[] = useDataQuery("attrs_solution");
  const attrs = kind === "problem" ? problemAttrs : solutionAttrs;

  const cardsProblem = useDataQuery(`cards_problem${problemId}`);

  const problem = useDataQuery(`problem${problemId}`);

  const cardsSolution: any[] = useDataQuery(
    `cards_problem${problemId}_solution${solutionId}`
  );

  const attrCards: any[] = kind === "problem" ? cardsProblem : cardsSolution;

  if (!attrCards) return <div>Loading attribute cards...</div>;
  if (!attrs) return <div>Loading attributes...</div>;
  if (!problem) return <div>Loading attributes...</div>;

  const topCardDescription =
    "#### " + problem?.title + "\n\n" + problem?.description;

  return (
    <Space
      id="ContainerElementID"
      direction="vertical"
      size="middle"
      style={{ display: "flex" }}
    >
      <div id="topcard">
        <CardSample
          mode={"wide"}
          key={"top"}
          meta={problem?.meta}
          description={topCardDescription}
        />
      </div>

      {/* 
<Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      style={{ background: token.colorBgContainer }}
      items={getItems(panelStyle)}
    /> */}

      {attrs.map((attr) => {
        const cards = attrCards.filter((card) => card.attr === attr.id);

        if (!cards.length) return null;

        return (
          <div
            key={attr.id}
            id={attr.id}
            style={{
              background: attr.lightColor || colorBgContainer,
              minHeight: 280,
              padding: 12,
              borderRadius: borderRadiusLG,
              // overflowX: "auto",
            }}
          >
            <Typography.Title
              level={4}
              style={{
                margin: "0",
              }}
            >
              <i>Атрибуты: </i>
              {attr.title}
            </Typography.Title>
            <Space
              direction="horizontal"
              align="start"
              size="middle"
              wrap
              style={{
                padding: 0,
              }}
            >
              {cards.map((card, index) => (
                <CardSample key={index} {...card} />
              ))}
            </Space>
          </div>
        );
      })}
    </Space>
  );
};

export default ContentContainer;
