import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const prefix = ""; //'/parti-js';

root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path={`${prefix}/`} element={<App />} />
        <Route path={`${prefix}/problems/`} element={<App />} />
        <Route path={`${prefix}/problems/:problemId/`} element={<App />} />
        <Route
          path={`${prefix}/problems/:problemId/solutions/:solutionId`}
          element={<App />}
        />
        <Route path="*" element={<div>404 No such page</div>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
