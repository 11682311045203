import { Avatar, Button, Flex, Space, Typography } from "antd";
import { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import Markdown from "react-markdown";
import staticPeople from "./data/people.json";

import CardSample from "./sample-card";
import { useDataQuery } from "./hooks/useData";

type prob = {
  shortTitle: string;
  title: string;
  description: string;
  meta: { author: string; date: string };
};

const { Text, Title } = Typography;

interface ProblemProps {
  problemId?: string;
  solutionId?: string;
  expanded?: boolean;
  setExpanded?: any;
  kind?: "problem" | "solution";
}

const ProblemSample: React.FC<ProblemProps> = ({
  problemId = "",
  solutionId = "",
  expanded = false,
  setExpanded,
  kind,
}) => {
  const [peopleData, setData] = useState<any[] | undefined>();

  const people = useDataQuery('people');


  const problemData = useDataQuery(`problem${problemId}`);
  const solutionData1 = useDataQuery(`problem${problemId}_solution0`);
  const solutionData2 = useDataQuery(`problem${problemId}_solution1`);
  
  const problem = problemData;

  if (!problem) return <div>Loading problem...</div>;
  if (kind === "solution" && !solutionData1)  return null;
  if (kind === "solution" && !solutionData2) return null;
  if (!people) return <div>Loading people...</div>;

  //@ts-ignore
  const person = people?.["c"] ?? staticPeople["c"];
  const { name, avatar } = person;
  const avatarElement = avatar ? (
    <Avatar size="large" src={avatar} />
  ) : (
    <UserOutlined />
  );

  const head = (
    <Flex
      justify="space-between"
      style={{ padding: "0px", fontWeight: "normal" }}
    >
      <Button value={123} onClick={() => setExpanded(!expanded)}>
        {problem.shortTitle}
      </Button>

      <Typography.Title level={5} style={{ margin: "0" }}>
        <i>Проблема</i>: {problem.title}
      </Typography.Title>

      <Space
        direction="horizontal"
        style={{ padding: "0px", margin: "0", fontWeight: "normal" }}
      >
        <Avatar size="large" icon={avatarElement} />
        <Flex vertical={true}>
          <Text strong>{name}</Text>
          <Text>11 июля 2024 в 15:45</Text>
        </Flex>
      </Space>
    </Flex>
  );

  const probActive = !window.location.hash.includes("solutions");
  const sol1Active = window.location.hash.includes("solutions/0");
  const sol2Active = window.location.hash.includes("solutions/1");

  const content0 = (
    <Space
      direction="horizontal"
      align="start"
      size="middle"
      wrap
      style={
        {
          // paddingRight: 24,
        }
      }
    >
      {kind === "problem" && (
        <div
          style={
            probActive
              ? {
                  border: "1px solid #1890ff",
                  borderRadius: "5px",
                  padding: "5px",
                }
              : {}
          }
        >
          <Typography.Title level={5} style={{ margin: "0" }}>
            <i>
              <a href="/#problems/0">Проблема</a>
            </i>
          </Typography.Title>

          <CardSample
            key={"a"}
            meta={problem.meta}
            description={problem.description}
          />
        </div>
      )}

      {kind === "solution" && (
        <>
          <div
            style={
              sol1Active
                ? {
                    border: "1px solid #1890ff",
                    borderRadius: "5px",
                    padding: "5px",
                  }
                : {}
            }
          >
            <Typography.Title level={5} style={{ margin: "0" }}>
              <i>
                <a href="/#problems/0/solutions/0">Решение 1</a>
              </i>
            </Typography.Title>

            <CardSample
              key={"b"}
              meta={solutionData1.meta}
              description={solutionData1.description}
            />
          </div>

          <div
            style={
              sol2Active
                ? {
                    border: "1px solid #1890ff",
                    borderRadius: "5px",
                    padding: "5px",
                  }
                : {}
            }
          >
            <Typography.Title level={5} style={{ margin: "0" }}>
              <i>
                <a href="/#problems/0/solutions/1">Решение 2</a>
              </i>
            </Typography.Title>

            <CardSample
              key={"c"}
              meta={solutionData2.meta}
              description={solutionData2.description}
            />
          </div>
        </>
      )}
    </Space>
  );

  const content = (
    <Typography.Paragraph
      ellipsis={{
        rows: 4,
        expandable: "collapsible",
        expanded: expanded,
        onExpand: (_, info) => setExpanded(info.expanded),
      }}
    >
      <Markdown>{problem.description}</Markdown>
    </Typography.Paragraph>
  );

  return expanded ? <Space>{content0}</Space> : null;
};

export default ProblemSample;
