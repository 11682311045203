import React, { useCallback, useState } from "react";
import { Button, ConfigProvider, Radio, Space } from "antd";
import { scroller } from "react-scroll";

import { useDataQuery } from "./hooks/useData";

type OwnProps = {
  kind?: "problem" | "solution";
  expanded?: boolean;
  setExpanded?: any;
  expanded2?: boolean;
  setExpanded2?: any;
  problemId?: string;
  solutionId?: string;
};

const AttrBar: React.FC<OwnProps> = ({
  kind = "problem",
  expanded,
  setExpanded,
  expanded2,
  setExpanded2,
  problemId,
  solutionId,
}) => {
  const [attrData, setData] = useState<any[] | undefined>();

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const toggle2 = useCallback(() => {
    setExpanded2(!expanded2);
  }, [expanded2, setExpanded2]);

  const problemAttrs: any[] = useDataQuery("attrs");
  const solutionAttrs: any[] = useDataQuery("attrs_solution");
  const attrs = kind === "problem" ? problemAttrs : solutionAttrs;

  const cardsProblem = useDataQuery(`cards_problem${problemId}`);

  const problem = useDataQuery(`problem${problemId}`);
  const solutionCount = problem?.solutions || 0;

  const cardsSolution: any[] = useDataQuery(
    `cards_problem${problemId}_solution${solutionId}`
  );

  const cards: any[] = kind === "problem" ? cardsProblem : cardsSolution;

  const [position, setPosition] = useState<"start" | "end">("end");
  const style = {
    whiteSpace: "normal",
    lineHeight: "1.6",
    width: "10em",
    minHeight: "4.5em",
    padding: "8px 12px",
    textAlign: "left",
  };

  const setTab = (tab: string) => {
    setPosition(tab as "start" | "end");
    const element = scroller.get(tab);

    scroller.scrollTo(tab, {
      duration: 1500,
      isDynamic: true,
      delay: 100,
      smooth: true,
      spy: true,
      // containerId: 'ContainerElementID',
      offset: -120,
    });
  };

  const setToTop = () => setTab("topcard");

  if (!attrs) return <div>Loading attribute types...</div>;

  if (!cards) return <div>Loading cards...</div>;

  return (
    <Space direction="horizontal" size="middle">
      <div>
        {/* <Title level={5} style={{ margin: "3px 0" }}>
          Проблема:
        </Title> */}
        {/*@ts-ignore*/}
        <Button value={123} style={style} onClick={setToTop}>
          {/* {problem.shortTitle} */}
          {kind === "problem" ? "Проблема" : "Решение"}
        </Button>
      </div>

      <div>
        {/* <Title level={5} style={{ margin: "3px 0" }}>
          Атрибуты проблемы
        </Title> */}
        <Radio.Group value={position} onChange={(e) => setTab(e.target.value)}>
          {attrs.map((attr) => {
            const hasCards =
              cards.filter((card) => {
                return card.attr === attr.id;
              }).length > 0;

            const color = hasCards && attr.color;

            return (
              <ConfigProvider
                key={attr.id}
                theme={{
                  components: {
                    Radio: {
                      colorPrimary: color ? "#fff" : "#00b96b",
                      buttonCheckedBg: color || "#f0f2f5",
                      buttonBg: color || "#f0f2f5",
                      buttonSolidCheckedColor: color ? "#fff" : "#000",
                      buttonColor: color ? "#fff" : "#000",
                      buttonCheckedColorDisabled: color ? "#fff" : "#000",
                      buttonSolidCheckedHoverBg: color ? "#fff" : "#000",
                    },
                  },
                }}
              >
                {/*@ts-ignore*/}
                <Radio.Button key={attr.id} value={attr.id} style={style}>
                  {attr.title}
                </Radio.Button>
              </ConfigProvider>
            );
          })}
        </Radio.Group>
      </div>
      <div>
        {/* <Title level={5} style={{ margin: "3px 0" }}>
          Проблема:
        </Title> */}
        {/*@ts-ignore*/}
        {!!solutionCount && (<Button value={123} style={style} onClick={toggle2}>
            {/* {problem.shortTitle} */}
            {kind === "problem" ? "Решения" : "Проблема"}
          </Button>)}
      </div>
    </Space>
  );
};

export default AttrBar;
