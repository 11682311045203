import React from "react";

import Problem from "./problem/Problem";
import Solution from "./solution/Solution";
import { useParams } from "react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { problemId } = useParams<{ problemId: string }>();
  const { solutionId } = useParams<{ solutionId: string }>();

  if (solutionId !== undefined && solutionId?.length > 0) {
    return (
      <QueryClientProvider client={queryClient}>
        <Solution solutionId={solutionId} problemId={problemId} />
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Problem solutionId={solutionId} problemId={problemId} />
    </QueryClientProvider>
  );
};

export default App;
